.button {
  border: 1px solid $border-active;
  text-transform: uppercase;
  font-size: 18px;
  @include font-family("regular");
  padding: 10px 30px;
  color: $white;
  display: flex;
  border-radius: 10px;
  width: fit-content;
  background: transparent;
  cursor: pointer;
  overflow: hidden;

  transition: $background-transition;

  @include mq($from: "tablet") {
    font-size: 22px;
  }

  &:hover, &:focus {
    background: $red;
  }

  &--small {
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 6px;
  }

  &--white {
    border: 1px solid $white;
    &:hover, &:focus {
      background: $white;
      color: $black-2;
    }
  }

  &.request-access {
    background-color: $dark-blue;
    align-items: center;
    justify-content: center;
    &:hover, &:focus {
      background: $red;
    }
  }

  &:focus-visible {
    outline: 0;
    border: 1px solid $red;
  }
}