.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none;
}