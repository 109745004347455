.paragraph {
  font-size: 16px;
  white-space: break-spaces;

  @include mq($from: "desktop") {
    font-size: 20px;
  }
  @include mq($from: "wide") {
    font-size: 22px;
  }
}