.newsPage {
  margin-bottom: 300px;
}

.listNews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.news {
  width: 100%;
  height: 100%;
  color: $white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &__infos{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
  }

  &__inner {
    background-color: $dark-blue;
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    @extend .hover-effect;
  }

  &__title {
    @include font-family("bold");
    max-height: 120px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 20px;
    font-size: 16px;
  }

  &__date {
    opacity: 0.4;
    font-size: 16px;
    margin-top: auto;
  }
}