// Credit: Nicolas Gallagher and SUIT CSS.

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 0;

  &::before {
    content: "";
    display: block;
    background: url("../svg/logo.svg") no-repeat center;
    background-size: 50% 50%;
    filter: grayscale(100%);
    opacity: .25;
    width: 100%;
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 0;
  }

  iframe,
  embed,
  object,
  video {
    height: 100%;
  }

  //safari border radius hack
  &-item {
    .news &{
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .our-format-list & {
      border-radius: 16px;
    }

    .format__img &, .format-panel__picture &{
      border-radius: 18px;
    }

    .panel-list-image &{
      border-radius: 5px;
    }

    .swiper-containerFormats & {
      border-radius: 9px;
    }

    .single-page-news &{
      border-radius: 16px;
    }
  }
}

.embed-responsive-21by9 {
  &::before {
    padding-top: percentage(9 / 21);
  }
}

.embed-responsive-16by9 {
  &::before {
    padding-top: percentage(9 / 16);
  }
}

.embed-responsive-4by3 {
  &::before {
    padding-top: percentage(3 / 4);
  }
}

.embed-responsive-1by1 {
  &::before {
    padding-top: percentage(1 / 1);
  }
}