.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-truncate {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}