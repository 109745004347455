img.lazy {
  opacity: 0;
}
img {
  transition: opacity 1s ease-in-out;
}
img.lazyloaded {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}