html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

/*COLORS*/
/*SHADOW*/
/*borders*/
/*background*/
html {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: #fff;
  line-height: 1.4;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding-top: 72px;
  background: linear-gradient(90deg, rgb(13, 22, 85) 0%, rgb(0, 9, 56) 50%, rgb(13, 22, 85) 100%);
}

footer {
  margin-top: auto;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.u-1\/1 {
  width: 100% !important;
}

.u-1\/2 {
  width: 50% !important;
}

.u-2\/2 {
  width: 100% !important;
}

.u-1\/3 {
  width: 33.3333333333% !important;
}

.u-2\/3 {
  width: 66.6666666667% !important;
}

.u-3\/3 {
  width: 100% !important;
}

.u-1\/4 {
  width: 25% !important;
}

.u-2\/4 {
  width: 50% !important;
}

.u-3\/4 {
  width: 75% !important;
}

.u-4\/4 {
  width: 100% !important;
}

.u-1\/5 {
  width: 20% !important;
}

.u-2\/5 {
  width: 40% !important;
}

.u-3\/5 {
  width: 60% !important;
}

.u-4\/5 {
  width: 80% !important;
}

.u-5\/5 {
  width: 100% !important;
}

.u-1\/6 {
  width: 16.6666666667% !important;
}

.u-2\/6 {
  width: 33.3333333333% !important;
}

.u-3\/6 {
  width: 50% !important;
}

.u-4\/6 {
  width: 66.6666666667% !important;
}

.u-5\/6 {
  width: 83.3333333333% !important;
}

.u-6\/6 {
  width: 100% !important;
}

.u-1\/7 {
  width: 14.2857142857% !important;
}

.u-2\/7 {
  width: 28.5714285714% !important;
}

.u-3\/7 {
  width: 42.8571428571% !important;
}

.u-4\/7 {
  width: 57.1428571429% !important;
}

.u-5\/7 {
  width: 71.4285714286% !important;
}

.u-6\/7 {
  width: 85.7142857143% !important;
}

.u-7\/7 {
  width: 100% !important;
}

.u-1\/8 {
  width: 12.5% !important;
}

.u-2\/8 {
  width: 25% !important;
}

.u-3\/8 {
  width: 37.5% !important;
}

.u-4\/8 {
  width: 50% !important;
}

.u-5\/8 {
  width: 62.5% !important;
}

.u-6\/8 {
  width: 75% !important;
}

.u-7\/8 {
  width: 87.5% !important;
}

.u-8\/8 {
  width: 100% !important;
}

.u-1\/9 {
  width: 11.1111111111% !important;
}

.u-2\/9 {
  width: 22.2222222222% !important;
}

.u-3\/9 {
  width: 33.3333333333% !important;
}

.u-4\/9 {
  width: 44.4444444444% !important;
}

.u-5\/9 {
  width: 55.5555555556% !important;
}

.u-6\/9 {
  width: 66.6666666667% !important;
}

.u-7\/9 {
  width: 77.7777777778% !important;
}

.u-8\/9 {
  width: 88.8888888889% !important;
}

.u-9\/9 {
  width: 100% !important;
}

.u-1\/10 {
  width: 10% !important;
}

.u-2\/10 {
  width: 20% !important;
}

.u-3\/10 {
  width: 30% !important;
}

.u-4\/10 {
  width: 40% !important;
}

.u-5\/10 {
  width: 50% !important;
}

.u-6\/10 {
  width: 60% !important;
}

.u-7\/10 {
  width: 70% !important;
}

.u-8\/10 {
  width: 80% !important;
}

.u-9\/10 {
  width: 90% !important;
}

.u-10\/10 {
  width: 100% !important;
}

.u-1\/11 {
  width: 9.0909090909% !important;
}

.u-2\/11 {
  width: 18.1818181818% !important;
}

.u-3\/11 {
  width: 27.2727272727% !important;
}

.u-4\/11 {
  width: 36.3636363636% !important;
}

.u-5\/11 {
  width: 45.4545454545% !important;
}

.u-6\/11 {
  width: 54.5454545455% !important;
}

.u-7\/11 {
  width: 63.6363636364% !important;
}

.u-8\/11 {
  width: 72.7272727273% !important;
}

.u-9\/11 {
  width: 81.8181818182% !important;
}

.u-10\/11 {
  width: 90.9090909091% !important;
}

.u-11\/11 {
  width: 100% !important;
}

.u-1\/12 {
  width: 8.3333333333% !important;
}

.u-2\/12 {
  width: 16.6666666667% !important;
}

.u-3\/12 {
  width: 25% !important;
}

.u-4\/12 {
  width: 33.3333333333% !important;
}

.u-5\/12 {
  width: 41.6666666667% !important;
}

.u-6\/12 {
  width: 50% !important;
}

.u-7\/12 {
  width: 58.3333333333% !important;
}

.u-8\/12 {
  width: 66.6666666667% !important;
}

.u-9\/12 {
  width: 75% !important;
}

.u-10\/12 {
  width: 83.3333333333% !important;
}

.u-11\/12 {
  width: 91.6666666667% !important;
}

.u-12\/12 {
  width: 100% !important;
}

@media (min-width: 415px) {
  .u-1\/1\@sm {
    width: 100% !important;
  }
  .u-1\/2\@sm {
    width: 50% !important;
  }
  .u-2\/2\@sm {
    width: 100% !important;
  }
  .u-1\/3\@sm {
    width: 33.3333333333% !important;
  }
  .u-2\/3\@sm {
    width: 66.6666666667% !important;
  }
  .u-3\/3\@sm {
    width: 100% !important;
  }
  .u-1\/4\@sm {
    width: 25% !important;
  }
  .u-2\/4\@sm {
    width: 50% !important;
  }
  .u-3\/4\@sm {
    width: 75% !important;
  }
  .u-4\/4\@sm {
    width: 100% !important;
  }
  .u-1\/5\@sm {
    width: 20% !important;
  }
  .u-2\/5\@sm {
    width: 40% !important;
  }
  .u-3\/5\@sm {
    width: 60% !important;
  }
  .u-4\/5\@sm {
    width: 80% !important;
  }
  .u-5\/5\@sm {
    width: 100% !important;
  }
  .u-1\/6\@sm {
    width: 16.6666666667% !important;
  }
  .u-2\/6\@sm {
    width: 33.3333333333% !important;
  }
  .u-3\/6\@sm {
    width: 50% !important;
  }
  .u-4\/6\@sm {
    width: 66.6666666667% !important;
  }
  .u-5\/6\@sm {
    width: 83.3333333333% !important;
  }
  .u-6\/6\@sm {
    width: 100% !important;
  }
  .u-1\/7\@sm {
    width: 14.2857142857% !important;
  }
  .u-2\/7\@sm {
    width: 28.5714285714% !important;
  }
  .u-3\/7\@sm {
    width: 42.8571428571% !important;
  }
  .u-4\/7\@sm {
    width: 57.1428571429% !important;
  }
  .u-5\/7\@sm {
    width: 71.4285714286% !important;
  }
  .u-6\/7\@sm {
    width: 85.7142857143% !important;
  }
  .u-7\/7\@sm {
    width: 100% !important;
  }
  .u-1\/8\@sm {
    width: 12.5% !important;
  }
  .u-2\/8\@sm {
    width: 25% !important;
  }
  .u-3\/8\@sm {
    width: 37.5% !important;
  }
  .u-4\/8\@sm {
    width: 50% !important;
  }
  .u-5\/8\@sm {
    width: 62.5% !important;
  }
  .u-6\/8\@sm {
    width: 75% !important;
  }
  .u-7\/8\@sm {
    width: 87.5% !important;
  }
  .u-8\/8\@sm {
    width: 100% !important;
  }
  .u-1\/9\@sm {
    width: 11.1111111111% !important;
  }
  .u-2\/9\@sm {
    width: 22.2222222222% !important;
  }
  .u-3\/9\@sm {
    width: 33.3333333333% !important;
  }
  .u-4\/9\@sm {
    width: 44.4444444444% !important;
  }
  .u-5\/9\@sm {
    width: 55.5555555556% !important;
  }
  .u-6\/9\@sm {
    width: 66.6666666667% !important;
  }
  .u-7\/9\@sm {
    width: 77.7777777778% !important;
  }
  .u-8\/9\@sm {
    width: 88.8888888889% !important;
  }
  .u-9\/9\@sm {
    width: 100% !important;
  }
  .u-1\/10\@sm {
    width: 10% !important;
  }
  .u-2\/10\@sm {
    width: 20% !important;
  }
  .u-3\/10\@sm {
    width: 30% !important;
  }
  .u-4\/10\@sm {
    width: 40% !important;
  }
  .u-5\/10\@sm {
    width: 50% !important;
  }
  .u-6\/10\@sm {
    width: 60% !important;
  }
  .u-7\/10\@sm {
    width: 70% !important;
  }
  .u-8\/10\@sm {
    width: 80% !important;
  }
  .u-9\/10\@sm {
    width: 90% !important;
  }
  .u-10\/10\@sm {
    width: 100% !important;
  }
  .u-1\/11\@sm {
    width: 9.0909090909% !important;
  }
  .u-2\/11\@sm {
    width: 18.1818181818% !important;
  }
  .u-3\/11\@sm {
    width: 27.2727272727% !important;
  }
  .u-4\/11\@sm {
    width: 36.3636363636% !important;
  }
  .u-5\/11\@sm {
    width: 45.4545454545% !important;
  }
  .u-6\/11\@sm {
    width: 54.5454545455% !important;
  }
  .u-7\/11\@sm {
    width: 63.6363636364% !important;
  }
  .u-8\/11\@sm {
    width: 72.7272727273% !important;
  }
  .u-9\/11\@sm {
    width: 81.8181818182% !important;
  }
  .u-10\/11\@sm {
    width: 90.9090909091% !important;
  }
  .u-11\/11\@sm {
    width: 100% !important;
  }
  .u-1\/12\@sm {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@sm {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@sm {
    width: 25% !important;
  }
  .u-4\/12\@sm {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@sm {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@sm {
    width: 50% !important;
  }
  .u-7\/12\@sm {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@sm {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@sm {
    width: 75% !important;
  }
  .u-10\/12\@sm {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@sm {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@sm {
    width: 100% !important;
  }
}
@media (min-width: 525px) {
  .u-1\/1\@smp {
    width: 100% !important;
  }
  .u-1\/2\@smp {
    width: 50% !important;
  }
  .u-2\/2\@smp {
    width: 100% !important;
  }
  .u-1\/3\@smp {
    width: 33.3333333333% !important;
  }
  .u-2\/3\@smp {
    width: 66.6666666667% !important;
  }
  .u-3\/3\@smp {
    width: 100% !important;
  }
  .u-1\/4\@smp {
    width: 25% !important;
  }
  .u-2\/4\@smp {
    width: 50% !important;
  }
  .u-3\/4\@smp {
    width: 75% !important;
  }
  .u-4\/4\@smp {
    width: 100% !important;
  }
  .u-1\/5\@smp {
    width: 20% !important;
  }
  .u-2\/5\@smp {
    width: 40% !important;
  }
  .u-3\/5\@smp {
    width: 60% !important;
  }
  .u-4\/5\@smp {
    width: 80% !important;
  }
  .u-5\/5\@smp {
    width: 100% !important;
  }
  .u-1\/6\@smp {
    width: 16.6666666667% !important;
  }
  .u-2\/6\@smp {
    width: 33.3333333333% !important;
  }
  .u-3\/6\@smp {
    width: 50% !important;
  }
  .u-4\/6\@smp {
    width: 66.6666666667% !important;
  }
  .u-5\/6\@smp {
    width: 83.3333333333% !important;
  }
  .u-6\/6\@smp {
    width: 100% !important;
  }
  .u-1\/7\@smp {
    width: 14.2857142857% !important;
  }
  .u-2\/7\@smp {
    width: 28.5714285714% !important;
  }
  .u-3\/7\@smp {
    width: 42.8571428571% !important;
  }
  .u-4\/7\@smp {
    width: 57.1428571429% !important;
  }
  .u-5\/7\@smp {
    width: 71.4285714286% !important;
  }
  .u-6\/7\@smp {
    width: 85.7142857143% !important;
  }
  .u-7\/7\@smp {
    width: 100% !important;
  }
  .u-1\/8\@smp {
    width: 12.5% !important;
  }
  .u-2\/8\@smp {
    width: 25% !important;
  }
  .u-3\/8\@smp {
    width: 37.5% !important;
  }
  .u-4\/8\@smp {
    width: 50% !important;
  }
  .u-5\/8\@smp {
    width: 62.5% !important;
  }
  .u-6\/8\@smp {
    width: 75% !important;
  }
  .u-7\/8\@smp {
    width: 87.5% !important;
  }
  .u-8\/8\@smp {
    width: 100% !important;
  }
  .u-1\/9\@smp {
    width: 11.1111111111% !important;
  }
  .u-2\/9\@smp {
    width: 22.2222222222% !important;
  }
  .u-3\/9\@smp {
    width: 33.3333333333% !important;
  }
  .u-4\/9\@smp {
    width: 44.4444444444% !important;
  }
  .u-5\/9\@smp {
    width: 55.5555555556% !important;
  }
  .u-6\/9\@smp {
    width: 66.6666666667% !important;
  }
  .u-7\/9\@smp {
    width: 77.7777777778% !important;
  }
  .u-8\/9\@smp {
    width: 88.8888888889% !important;
  }
  .u-9\/9\@smp {
    width: 100% !important;
  }
  .u-1\/10\@smp {
    width: 10% !important;
  }
  .u-2\/10\@smp {
    width: 20% !important;
  }
  .u-3\/10\@smp {
    width: 30% !important;
  }
  .u-4\/10\@smp {
    width: 40% !important;
  }
  .u-5\/10\@smp {
    width: 50% !important;
  }
  .u-6\/10\@smp {
    width: 60% !important;
  }
  .u-7\/10\@smp {
    width: 70% !important;
  }
  .u-8\/10\@smp {
    width: 80% !important;
  }
  .u-9\/10\@smp {
    width: 90% !important;
  }
  .u-10\/10\@smp {
    width: 100% !important;
  }
  .u-1\/11\@smp {
    width: 9.0909090909% !important;
  }
  .u-2\/11\@smp {
    width: 18.1818181818% !important;
  }
  .u-3\/11\@smp {
    width: 27.2727272727% !important;
  }
  .u-4\/11\@smp {
    width: 36.3636363636% !important;
  }
  .u-5\/11\@smp {
    width: 45.4545454545% !important;
  }
  .u-6\/11\@smp {
    width: 54.5454545455% !important;
  }
  .u-7\/11\@smp {
    width: 63.6363636364% !important;
  }
  .u-8\/11\@smp {
    width: 72.7272727273% !important;
  }
  .u-9\/11\@smp {
    width: 81.8181818182% !important;
  }
  .u-10\/11\@smp {
    width: 90.9090909091% !important;
  }
  .u-11\/11\@smp {
    width: 100% !important;
  }
  .u-1\/12\@smp {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@smp {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@smp {
    width: 25% !important;
  }
  .u-4\/12\@smp {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@smp {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@smp {
    width: 50% !important;
  }
  .u-7\/12\@smp {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@smp {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@smp {
    width: 75% !important;
  }
  .u-10\/12\@smp {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@smp {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@smp {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .u-1\/1\@md {
    width: 100% !important;
  }
  .u-1\/2\@md {
    width: 50% !important;
  }
  .u-2\/2\@md {
    width: 100% !important;
  }
  .u-1\/3\@md {
    width: 33.3333333333% !important;
  }
  .u-2\/3\@md {
    width: 66.6666666667% !important;
  }
  .u-3\/3\@md {
    width: 100% !important;
  }
  .u-1\/4\@md {
    width: 25% !important;
  }
  .u-2\/4\@md {
    width: 50% !important;
  }
  .u-3\/4\@md {
    width: 75% !important;
  }
  .u-4\/4\@md {
    width: 100% !important;
  }
  .u-1\/5\@md {
    width: 20% !important;
  }
  .u-2\/5\@md {
    width: 40% !important;
  }
  .u-3\/5\@md {
    width: 60% !important;
  }
  .u-4\/5\@md {
    width: 80% !important;
  }
  .u-5\/5\@md {
    width: 100% !important;
  }
  .u-1\/6\@md {
    width: 16.6666666667% !important;
  }
  .u-2\/6\@md {
    width: 33.3333333333% !important;
  }
  .u-3\/6\@md {
    width: 50% !important;
  }
  .u-4\/6\@md {
    width: 66.6666666667% !important;
  }
  .u-5\/6\@md {
    width: 83.3333333333% !important;
  }
  .u-6\/6\@md {
    width: 100% !important;
  }
  .u-1\/7\@md {
    width: 14.2857142857% !important;
  }
  .u-2\/7\@md {
    width: 28.5714285714% !important;
  }
  .u-3\/7\@md {
    width: 42.8571428571% !important;
  }
  .u-4\/7\@md {
    width: 57.1428571429% !important;
  }
  .u-5\/7\@md {
    width: 71.4285714286% !important;
  }
  .u-6\/7\@md {
    width: 85.7142857143% !important;
  }
  .u-7\/7\@md {
    width: 100% !important;
  }
  .u-1\/8\@md {
    width: 12.5% !important;
  }
  .u-2\/8\@md {
    width: 25% !important;
  }
  .u-3\/8\@md {
    width: 37.5% !important;
  }
  .u-4\/8\@md {
    width: 50% !important;
  }
  .u-5\/8\@md {
    width: 62.5% !important;
  }
  .u-6\/8\@md {
    width: 75% !important;
  }
  .u-7\/8\@md {
    width: 87.5% !important;
  }
  .u-8\/8\@md {
    width: 100% !important;
  }
  .u-1\/9\@md {
    width: 11.1111111111% !important;
  }
  .u-2\/9\@md {
    width: 22.2222222222% !important;
  }
  .u-3\/9\@md {
    width: 33.3333333333% !important;
  }
  .u-4\/9\@md {
    width: 44.4444444444% !important;
  }
  .u-5\/9\@md {
    width: 55.5555555556% !important;
  }
  .u-6\/9\@md {
    width: 66.6666666667% !important;
  }
  .u-7\/9\@md {
    width: 77.7777777778% !important;
  }
  .u-8\/9\@md {
    width: 88.8888888889% !important;
  }
  .u-9\/9\@md {
    width: 100% !important;
  }
  .u-1\/10\@md {
    width: 10% !important;
  }
  .u-2\/10\@md {
    width: 20% !important;
  }
  .u-3\/10\@md {
    width: 30% !important;
  }
  .u-4\/10\@md {
    width: 40% !important;
  }
  .u-5\/10\@md {
    width: 50% !important;
  }
  .u-6\/10\@md {
    width: 60% !important;
  }
  .u-7\/10\@md {
    width: 70% !important;
  }
  .u-8\/10\@md {
    width: 80% !important;
  }
  .u-9\/10\@md {
    width: 90% !important;
  }
  .u-10\/10\@md {
    width: 100% !important;
  }
  .u-1\/11\@md {
    width: 9.0909090909% !important;
  }
  .u-2\/11\@md {
    width: 18.1818181818% !important;
  }
  .u-3\/11\@md {
    width: 27.2727272727% !important;
  }
  .u-4\/11\@md {
    width: 36.3636363636% !important;
  }
  .u-5\/11\@md {
    width: 45.4545454545% !important;
  }
  .u-6\/11\@md {
    width: 54.5454545455% !important;
  }
  .u-7\/11\@md {
    width: 63.6363636364% !important;
  }
  .u-8\/11\@md {
    width: 72.7272727273% !important;
  }
  .u-9\/11\@md {
    width: 81.8181818182% !important;
  }
  .u-10\/11\@md {
    width: 90.9090909091% !important;
  }
  .u-11\/11\@md {
    width: 100% !important;
  }
  .u-1\/12\@md {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@md {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@md {
    width: 25% !important;
  }
  .u-4\/12\@md {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@md {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@md {
    width: 50% !important;
  }
  .u-7\/12\@md {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@md {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@md {
    width: 75% !important;
  }
  .u-10\/12\@md {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@md {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@md {
    width: 100% !important;
  }
}
@media (min-width: 1024px) {
  .u-1\/1\@lg {
    width: 100% !important;
  }
  .u-1\/2\@lg {
    width: 50% !important;
  }
  .u-2\/2\@lg {
    width: 100% !important;
  }
  .u-1\/3\@lg {
    width: 33.3333333333% !important;
  }
  .u-2\/3\@lg {
    width: 66.6666666667% !important;
  }
  .u-3\/3\@lg {
    width: 100% !important;
  }
  .u-1\/4\@lg {
    width: 25% !important;
  }
  .u-2\/4\@lg {
    width: 50% !important;
  }
  .u-3\/4\@lg {
    width: 75% !important;
  }
  .u-4\/4\@lg {
    width: 100% !important;
  }
  .u-1\/5\@lg {
    width: 20% !important;
  }
  .u-2\/5\@lg {
    width: 40% !important;
  }
  .u-3\/5\@lg {
    width: 60% !important;
  }
  .u-4\/5\@lg {
    width: 80% !important;
  }
  .u-5\/5\@lg {
    width: 100% !important;
  }
  .u-1\/6\@lg {
    width: 16.6666666667% !important;
  }
  .u-2\/6\@lg {
    width: 33.3333333333% !important;
  }
  .u-3\/6\@lg {
    width: 50% !important;
  }
  .u-4\/6\@lg {
    width: 66.6666666667% !important;
  }
  .u-5\/6\@lg {
    width: 83.3333333333% !important;
  }
  .u-6\/6\@lg {
    width: 100% !important;
  }
  .u-1\/7\@lg {
    width: 14.2857142857% !important;
  }
  .u-2\/7\@lg {
    width: 28.5714285714% !important;
  }
  .u-3\/7\@lg {
    width: 42.8571428571% !important;
  }
  .u-4\/7\@lg {
    width: 57.1428571429% !important;
  }
  .u-5\/7\@lg {
    width: 71.4285714286% !important;
  }
  .u-6\/7\@lg {
    width: 85.7142857143% !important;
  }
  .u-7\/7\@lg {
    width: 100% !important;
  }
  .u-1\/8\@lg {
    width: 12.5% !important;
  }
  .u-2\/8\@lg {
    width: 25% !important;
  }
  .u-3\/8\@lg {
    width: 37.5% !important;
  }
  .u-4\/8\@lg {
    width: 50% !important;
  }
  .u-5\/8\@lg {
    width: 62.5% !important;
  }
  .u-6\/8\@lg {
    width: 75% !important;
  }
  .u-7\/8\@lg {
    width: 87.5% !important;
  }
  .u-8\/8\@lg {
    width: 100% !important;
  }
  .u-1\/9\@lg {
    width: 11.1111111111% !important;
  }
  .u-2\/9\@lg {
    width: 22.2222222222% !important;
  }
  .u-3\/9\@lg {
    width: 33.3333333333% !important;
  }
  .u-4\/9\@lg {
    width: 44.4444444444% !important;
  }
  .u-5\/9\@lg {
    width: 55.5555555556% !important;
  }
  .u-6\/9\@lg {
    width: 66.6666666667% !important;
  }
  .u-7\/9\@lg {
    width: 77.7777777778% !important;
  }
  .u-8\/9\@lg {
    width: 88.8888888889% !important;
  }
  .u-9\/9\@lg {
    width: 100% !important;
  }
  .u-1\/10\@lg {
    width: 10% !important;
  }
  .u-2\/10\@lg {
    width: 20% !important;
  }
  .u-3\/10\@lg {
    width: 30% !important;
  }
  .u-4\/10\@lg {
    width: 40% !important;
  }
  .u-5\/10\@lg {
    width: 50% !important;
  }
  .u-6\/10\@lg {
    width: 60% !important;
  }
  .u-7\/10\@lg {
    width: 70% !important;
  }
  .u-8\/10\@lg {
    width: 80% !important;
  }
  .u-9\/10\@lg {
    width: 90% !important;
  }
  .u-10\/10\@lg {
    width: 100% !important;
  }
  .u-1\/11\@lg {
    width: 9.0909090909% !important;
  }
  .u-2\/11\@lg {
    width: 18.1818181818% !important;
  }
  .u-3\/11\@lg {
    width: 27.2727272727% !important;
  }
  .u-4\/11\@lg {
    width: 36.3636363636% !important;
  }
  .u-5\/11\@lg {
    width: 45.4545454545% !important;
  }
  .u-6\/11\@lg {
    width: 54.5454545455% !important;
  }
  .u-7\/11\@lg {
    width: 63.6363636364% !important;
  }
  .u-8\/11\@lg {
    width: 72.7272727273% !important;
  }
  .u-9\/11\@lg {
    width: 81.8181818182% !important;
  }
  .u-10\/11\@lg {
    width: 90.9090909091% !important;
  }
  .u-11\/11\@lg {
    width: 100% !important;
  }
  .u-1\/12\@lg {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@lg {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@lg {
    width: 25% !important;
  }
  .u-4\/12\@lg {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@lg {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@lg {
    width: 50% !important;
  }
  .u-7\/12\@lg {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@lg {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@lg {
    width: 75% !important;
  }
  .u-10\/12\@lg {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@lg {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@lg {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .u-1\/1\@xl {
    width: 100% !important;
  }
  .u-1\/2\@xl {
    width: 50% !important;
  }
  .u-2\/2\@xl {
    width: 100% !important;
  }
  .u-1\/3\@xl {
    width: 33.3333333333% !important;
  }
  .u-2\/3\@xl {
    width: 66.6666666667% !important;
  }
  .u-3\/3\@xl {
    width: 100% !important;
  }
  .u-1\/4\@xl {
    width: 25% !important;
  }
  .u-2\/4\@xl {
    width: 50% !important;
  }
  .u-3\/4\@xl {
    width: 75% !important;
  }
  .u-4\/4\@xl {
    width: 100% !important;
  }
  .u-1\/5\@xl {
    width: 20% !important;
  }
  .u-2\/5\@xl {
    width: 40% !important;
  }
  .u-3\/5\@xl {
    width: 60% !important;
  }
  .u-4\/5\@xl {
    width: 80% !important;
  }
  .u-5\/5\@xl {
    width: 100% !important;
  }
  .u-1\/6\@xl {
    width: 16.6666666667% !important;
  }
  .u-2\/6\@xl {
    width: 33.3333333333% !important;
  }
  .u-3\/6\@xl {
    width: 50% !important;
  }
  .u-4\/6\@xl {
    width: 66.6666666667% !important;
  }
  .u-5\/6\@xl {
    width: 83.3333333333% !important;
  }
  .u-6\/6\@xl {
    width: 100% !important;
  }
  .u-1\/7\@xl {
    width: 14.2857142857% !important;
  }
  .u-2\/7\@xl {
    width: 28.5714285714% !important;
  }
  .u-3\/7\@xl {
    width: 42.8571428571% !important;
  }
  .u-4\/7\@xl {
    width: 57.1428571429% !important;
  }
  .u-5\/7\@xl {
    width: 71.4285714286% !important;
  }
  .u-6\/7\@xl {
    width: 85.7142857143% !important;
  }
  .u-7\/7\@xl {
    width: 100% !important;
  }
  .u-1\/8\@xl {
    width: 12.5% !important;
  }
  .u-2\/8\@xl {
    width: 25% !important;
  }
  .u-3\/8\@xl {
    width: 37.5% !important;
  }
  .u-4\/8\@xl {
    width: 50% !important;
  }
  .u-5\/8\@xl {
    width: 62.5% !important;
  }
  .u-6\/8\@xl {
    width: 75% !important;
  }
  .u-7\/8\@xl {
    width: 87.5% !important;
  }
  .u-8\/8\@xl {
    width: 100% !important;
  }
  .u-1\/9\@xl {
    width: 11.1111111111% !important;
  }
  .u-2\/9\@xl {
    width: 22.2222222222% !important;
  }
  .u-3\/9\@xl {
    width: 33.3333333333% !important;
  }
  .u-4\/9\@xl {
    width: 44.4444444444% !important;
  }
  .u-5\/9\@xl {
    width: 55.5555555556% !important;
  }
  .u-6\/9\@xl {
    width: 66.6666666667% !important;
  }
  .u-7\/9\@xl {
    width: 77.7777777778% !important;
  }
  .u-8\/9\@xl {
    width: 88.8888888889% !important;
  }
  .u-9\/9\@xl {
    width: 100% !important;
  }
  .u-1\/10\@xl {
    width: 10% !important;
  }
  .u-2\/10\@xl {
    width: 20% !important;
  }
  .u-3\/10\@xl {
    width: 30% !important;
  }
  .u-4\/10\@xl {
    width: 40% !important;
  }
  .u-5\/10\@xl {
    width: 50% !important;
  }
  .u-6\/10\@xl {
    width: 60% !important;
  }
  .u-7\/10\@xl {
    width: 70% !important;
  }
  .u-8\/10\@xl {
    width: 80% !important;
  }
  .u-9\/10\@xl {
    width: 90% !important;
  }
  .u-10\/10\@xl {
    width: 100% !important;
  }
  .u-1\/11\@xl {
    width: 9.0909090909% !important;
  }
  .u-2\/11\@xl {
    width: 18.1818181818% !important;
  }
  .u-3\/11\@xl {
    width: 27.2727272727% !important;
  }
  .u-4\/11\@xl {
    width: 36.3636363636% !important;
  }
  .u-5\/11\@xl {
    width: 45.4545454545% !important;
  }
  .u-6\/11\@xl {
    width: 54.5454545455% !important;
  }
  .u-7\/11\@xl {
    width: 63.6363636364% !important;
  }
  .u-8\/11\@xl {
    width: 72.7272727273% !important;
  }
  .u-9\/11\@xl {
    width: 81.8181818182% !important;
  }
  .u-10\/11\@xl {
    width: 90.9090909091% !important;
  }
  .u-11\/11\@xl {
    width: 100% !important;
  }
  .u-1\/12\@xl {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@xl {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@xl {
    width: 25% !important;
  }
  .u-4\/12\@xl {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@xl {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@xl {
    width: 50% !important;
  }
  .u-7\/12\@xl {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@xl {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@xl {
    width: 75% !important;
  }
  .u-10\/12\@xl {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@xl {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@xl {
    width: 100% !important;
  }
}
a {
  text-decoration: none;
  color: #fff;
}
.description-new__text a, .descriptionFormat a {
  border-bottom: 1px solid #F00519;
  text-transform: capitalize;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}

.u-global-link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: transparent;
}

.layout {
  padding: 0;
  list-style: none;
  font-size: 0;
  display: flex;
  margin: 0 0 0 -20px;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 768px) {
  .layout {
    margin: 0 0 0 -30px;
  }
}
.layout__item {
  padding-left: 20px;
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .layout__item {
    padding-left: 30px;
    margin-bottom: 30px;
  }
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none;
}

.position-relative {
  position: relative;
}

.hover-effect, .news__inner, .format__inner {
  transition: transform 0.25s ease, background-color 0.3s ease;
}
@media (min-width: 768px) {
  .hover-effect:hover, .news__inner:hover, .format__inner:hover {
    transform: translateY(-4px);
    background-color: #1b2f6e;
  }
  .swiper-slide .hover-effect:hover, .swiper-slide .news__inner:hover, .swiper-slide .format__inner:hover {
    transform: none;
  }
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: 0 auto;
}

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.embed-responsive::before {
  content: "";
  display: block;
  background: url("../svg/logo.svg") no-repeat center;
  background-size: 50% 50%;
  filter: grayscale(100%);
  opacity: 0.25;
  width: 100%;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
}
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  height: 100%;
}
.news .embed-responsive-item {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.our-format-list .embed-responsive-item {
  border-radius: 16px;
}
.format__img .embed-responsive-item, .format-panel__picture .embed-responsive-item {
  border-radius: 18px;
}
.panel-list-image .embed-responsive-item {
  border-radius: 5px;
}
.swiper-containerFormats .embed-responsive-item {
  border-radius: 9px;
}
.single-page-news .embed-responsive-item {
  border-radius: 16px;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.h-100 {
  height: 100%;
}

img.lazy {
  opacity: 0;
}

img {
  transition: opacity 1s ease-in-out;
}

img.lazyloaded {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}

.img-with-border, .format-panel__picture, .format__img {
  border-radius: 18px;
  overflow: hidden;
}

.img-with-border--small, .list-other-format__img-content > div {
  border-radius: 9px;
  overflow: hidden;
}

.transition-all, div.swiper-button-next, div.swiper-button-prev {
  transition: all 0.3s ease;
}

.headerPanel {
  height: 72px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
  background-color: #182757;
}
@media (min-width: 768px) {
  .headerPanel {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3490196078);
    border-bottom: 1px solid rgba(255, 255, 255, 0.035);
  }
}

.headerItem {
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  height: 100%;
  transition: 225ms ease-in-out;
  position: relative;
  font-family: "LatoBold", sans-serif;
}

.headerItem:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.navbar-toggler {
  display: block;
  padding: 0;
  margin: 0 22px 0 auto;
  background-color: transparent;
  appearance: unset;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 0px;
}
@media (min-width: 768px) {
  .navbar-toggler {
    display: none;
  }
}
.navbar-toggler > img {
  display: initial;
  width: auto;
}

.menuMobile {
  visibility: hidden;
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #192857;
  z-index: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: visibility 0s, opacity 250ms;
  padding-bottom: 72px;
}

.header-logo {
  max-width: 64px;
}
@media (min-width: 768px) {
  .header-logo {
    max-width: 88px;
  }
}

@media screen and (max-width: 768px) {
  .headerItem {
    visibility: hidden;
  }
  .menuMobileVisible > .menuMobile {
    opacity: 1;
    visibility: visible;
  }
  .headerItemMobile {
    font-size: 18px;
    color: #fff;
    font-family: "LatoBold", sans-serif;
    text-transform: uppercase;
    position: relative;
    height: 72px;
    line-height: 72px;
  }
}
@media screen and (max-width: 768px) and (max-width: 767px) {
  .headerItemMobile {
    text-align: center;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .headerItemMobile:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 28px;
    top: 100%;
    border-bottom: 1px solid #F00519;
    left: 50%;
    transform: translateX(-50%);
  }
  .iconMenu {
    opacity: 1;
    transition: 115ms;
    right: 22px;
  }
  .iconMenuCross {
    position: absolute;
    opacity: 0;
    left: 3px;
    transform: rotate(-45deg);
    transition: 115ms;
  }
  .menuMobileVisible > .navbar-toggler > .iconMenu {
    opacity: 0;
    transform: rotate(45deg);
  }
  .menuMobileVisible > .navbar-toggler > .iconMenuCross {
    opacity: 1;
    transform: rotate(0deg);
  }
  .homeMobile {
    visibility: visible;
  }
}
.listPanel {
  margin-bottom: 60px;
  overflow-x: hidden;
}

@media (min-width: 525px) {
  .ourFormat-1 {
    grid-column: 0/2;
    grid-row: 1/2;
  }
}

@media (min-width: 525px) {
  .ourFormat-2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

@media (min-width: 525px) {
  .ourFormat-3 {
    grid-column: 2/4;
    grid-row: 1/3;
  }
}

.panel-about {
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  gap: 0;
  background-color: #0c1531;
}
@media (min-width: 1024px) {
  .panel-about {
    gap: 40px;
    flex-direction: row;
    padding: 0;
  }
}
.panel-about__img {
  width: 100%;
}
@media (min-width: 1024px) {
  .panel-about__img {
    width: 60%;
  }
}
.panel-about__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 100%;
}
@media (min-width: 1024px) {
  .panel-about__text {
    padding: 20px 20px 20px 0;
    width: 40%;
  }
}
.panel-about__container {
  margin-bottom: 40px;
}
@media (min-width: 1024px) {
  .panel-about__container {
    margin-bottom: 90px;
  }
}

.imgAbout {
  width: 100%;
  max-width: 100%;
}

.footer {
  padding: 20px;
  background: #182757;
}
@media (min-width: 1200px) {
  .footer {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.footer__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
@media (min-width: 525px) {
  .footer__content {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .footer__content {
    padding-bottom: 0;
  }
}
.footer__brand {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer__logo {
  max-width: 80px;
  width: 100%;
}
.footer__links {
  color: #fff;
  display: flex;
  gap: 20px;
}
.footer__link {
  color: currentColor;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
@media (min-width: 768px) {
  .footer__link {
    font-size: 16px;
  }
}
.footer__copyright {
  opacity: 0.5;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}
.footer__title {
  font-size: 14px;
  color: #acacac;
  display: none;
  font-family: "Lato", sans-serif;
}
@media (min-width: 768px) {
  .footer__title {
    display: block;
  }
}

.filterPanel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 28px;
  font-size: 16px;
  gap: 5px;
  font-family: "Lato", sans-serif;
}
@media (min-width: 525px) {
  .filterPanel {
    gap: 10px;
  }
}
@media (min-width: 768px) {
  .filterPanel {
    gap: 20px;
  }
}

.filterText {
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .filterText {
    text-align: initial;
    width: auto;
    margin-bottom: 0;
  }
}

.filter {
  color: #fff;
  padding: 6px 15px;
  border-radius: 8px;
  opacity: 0.7;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
}
@media (min-width: 768px) {
  .filter {
    padding: 12px 24px;
  }
}

.filterActive, .filter:hover {
  opacity: 1;
  background: transparent;
  border: 1px solid #F00519;
  transition: background 0.15s ease-in;
}
.filterActive:hover, .filter:hover:hover {
  background: #F00519;
}

.format {
  color: #fff;
  display: flex;
  padding: 12px;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}
.swiper-containerFormats .format {
  height: initial;
}
@media (min-width: 1200px) {
  .format {
    flex-direction: row;
    gap: 15px;
  }
}
.format__img {
  width: 100%;
}
@media (min-width: 1200px) {
  .format__img {
    width: 60%;
  }
}
.format__description {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  flex-grow: 1;
}
@media (min-width: 1200px) {
  .swiper-containerFormats .format__description {
    gap: initial;
  }
}
@media (min-width: 1200px) {
  .format__description {
    margin-top: 0;
    flex-grow: initial;
  }
}
@media (min-width: 1200px) {
  .format__description {
    width: 40%;
  }
}
.swiper-containerFormats .format__description {
  width: 100%;
}
@media (min-width: 1200px) {
  .swiper-containerFormats .format__description {
    width: 50%;
  }
}
.format__inner {
  background: #0c1531;
  border-radius: 25px;
  height: 100%;
  width: 100%;
}
.format__genre {
  display: inline-block;
  padding: 4px 7px;
  border-radius: 6px;
  background-color: #224370;
  margin: auto auto 0;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
@media (min-width: 1200px) {
  .format__genre {
    display: none;
  }
}
.format__subtitle {
  opacity: 0.8;
  font-size: 16px;
  text-align: center;
}
@media (min-width: 1200px) {
  .format__subtitle {
    text-align: left;
  }
}
@media (max-width: 1199px) {
  .format__subtitle.text-truncate {
    white-space: initial;
  }
}

.formatsTitleFormat {
  font-family: "LatoBold", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 17px;
}
@media (min-width: 1200px) {
  .formatsTitleFormat {
    text-align: initial;
  }
}

.infosFormat {
  margin-top: auto;
  flex-direction: column;
  display: none;
}
@media (min-width: 1024px) {
  .infosFormat {
    display: flex;
  }
}

.infoFormat {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
@media (min-width: 1200px) {
  .infoFormat {
    margin-bottom: 0;
  }
}

.infoTitleFormat {
  opacity: 0.8;
  margin-right: auto;
  font-size: 16px;
}

.infoValueFormat {
  font-family: "LatoBold", sans-serif;
  font-size: 16px;
}

.newsPage {
  margin-bottom: 300px;
}

.listNews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.news {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
.news__infos {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
}
.news__inner {
  background-color: #0c1531;
  border-radius: 16px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.news__title {
  font-family: "LatoBold", sans-serif;
  max-height: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
  font-size: 16px;
}
.news__date {
  opacity: 0.4;
  font-size: 16px;
  margin-top: auto;
}

.date-new {
  opacity: 0.7;
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .date-new {
    font-size: 22px;
  }
}

.titleNew {
  font-family: "LatoBold", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .titleNew {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .titleNew {
    font-size: 34px;
  }
}

.subTitleNew {
  opacity: 0.8;
  font-family: "LatoBold", sans-serif;
  font-size: 16px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .subTitleNew {
    font-size: 22px;
  }
}

.newPicture {
  width: 100%;
  display: block;
}

.picture {
  border-radius: 16px;
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto 40px;
}

.description-new {
  margin-bottom: 40px;
}
.description-new__text {
  margin-bottom: 20px;
}
.description-new__chapo {
  font-weight: bold;
}

.titleFormat {
  margin-top: 52px;
  margin-bottom: 5px;
}

.subTitleFormat {
  opacity: 0.8;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .subTitleFormat {
    font-size: 24px;
  }
}

.format-panel {
  margin-bottom: 40px;
  display: grid;
  gap: 20px;
}
@media (min-width: 1024px) {
  .format-panel {
    grid-template-columns: 60% 1fr;
    gap: 20px;
  }
}
@media (min-width: 1024px) {
  .format-panel {
    gap: 40px;
  }
}
.format-panel__picture-overlay {
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  z-index: -1;
  display: none;
}
@media (min-width: 768px) {
  .format-panel__picture-overlay {
    display: block;
  }
}
.format-panel__picture-overlay > img {
  filter: blur(20px);
  opacity: 0.25;
}
.format-panel__infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.format-panel__infos-inner {
  background-color: #0c1531;
  justify-content: center;
  border-radius: 18px;
  padding: 25px 22px;
}
.format-panel__title-info {
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 1200px) {
  .format-panel__title-info {
    margin-bottom: 40px;
  }
}
.format-panel__image {
  border-radius: 18px;
  overflow: hidden;
  display: block;
}

.formatInfo {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .formatInfo {
    font-size: 20px;
  }
}

.formatInfo:last-child {
  margin-bottom: 0;
}

.formatInfoTitle {
  opacity: 0.8;
  margin-right: auto;
}

.formatEpisodesPanel {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  justify-content: center;
}

.format-episode {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  width: 155px;
  gap: 10px;
  height: 50px;
  display: grid;
  grid-template-columns: 10px 1fr 15px;
  align-items: center;
  border-radius: 18px;
  background-color: #182a63;
  cursor: pointer;
  user-select: none;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .format-episode {
    width: 202px;
    font-size: 16px;
  }
}

.descriptionFormat {
  margin-bottom: 40px;
}

.player {
  width: 100%;
}
.player > iframe {
  min-height: 300px;
}
@media (min-width: 768px) {
  .player > iframe {
    min-height: 500px;
  }
}
@media (min-width: 1200px) {
  .player > iframe {
    min-height: 720px;
  }
}

.background-contact {
  background: #0c1553;
}

.form-contact {
  font-family: "Lato", sans-serif;
  font-size: 20px;
}
.form-contact__input {
  margin-bottom: 20px;
  position: relative;
}
.form-contact__input--small {
  margin: 0;
}
.form-contact__button {
  display: flex;
  justify-content: center;
}
.form-contact__label {
  position: absolute;
  top: -10px;
  left: 20px;
  color: #fff;
  font-size: 16px;
  background: #0c1553;
  padding: 0 10px;
}
.form-contact__label--format {
  background: #101d41;
}
.form-contact__text {
  font-size: 14px;
  opacity: 0.5;
  font-family: "Roboto", sans-serif;
  display: none;
}
@media (min-width: 768px) {
  .form-contact__text {
    display: block;
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  .form-contact__text {
    font-size: 18px;
  }
}
.form-contact__title {
  font-family: "LatoBold", sans-serif;
  font-size: 18px;
}
@media (min-width: 768px) {
  .form-contact__title {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .form-contact__title {
    font-size: 24px;
  }
}
.form-contact__input-field--medium {
  max-height: 125px;
}
@media (min-width: 768px) {
  .form-contact__input-field--medium {
    max-height: 150px;
  }
}
@media (min-width: 1024px) {
  .form-contact__input-field--medium {
    max-height: 200px;
  }
}
.form-contact__input-field {
  font-size: 18px;
}

.email-response {
  position: absolute;
  top: 100%;
  left: 0;
  background: red;
  color: #fff;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  display: none;
  font-size: 14px;
  z-index: 3;
}
.email-response.visible {
  display: block;
}

.send {
  padding: 16px 42px;
  border: solid 1px #F00519;
  border-radius: 15px;
  background: transparent;
  color: white;
  width: fit-content;
  grid-column: 1/4;
  cursor: pointer;
  margin: 0 auto;
}

.carouselPanel {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow-x: hidden;
  position: relative;
}
@media (min-width: 1024px) {
  .carouselPanel {
    margin-top: 65px;
    margin-bottom: 60px;
  }
}

.title-carousel {
  font-size: 28px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .title-carousel {
    font-size: 38px;
  }
}

.main-title-carousel {
  font-family: "Lato", sans-serif;
  color: #F00519;
}

.subtitle-carousel {
  font-family: "LatoBold", sans-serif;
  color: #fff;
}

.carousel {
  position: relative;
  margin-top: 40px;
}

div.swiper-button-next--home, div.swiper-button-prev--home {
  display: flex;
  top: 55%;
}

div.swiper-button-next::after, div.swiper-button-prev::after {
  content: "";
}

div.swiper-button-next.swiper-button-disabled, div.swiper-button-prev.swiper-button-disabled {
  pointer-events: initial;
}

@media screen and (max-width: 1900px) {
  .swiper-slide {
    min-height: auto;
  }
}
.list-other-format.listPanel {
  margin-bottom: 45px;
}
.list-other-format__img-content {
  width: 100%;
}
@media (min-width: 1200px) {
  .list-other-format__img-content {
    width: 50%;
  }
}

div.swiper-button-next--formats, div.swiper-button-prev--formats {
  top: 42%;
}

.aboutPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 130px;
}

.textsAboutPanel {
  display: flex;
  flex-direction: column;
}

h1 {
  all: unset;
}

h2 {
  all: unset;
}

.main-title {
  text-transform: uppercase;
  font-family: "LatoBold", sans-serif;
  margin-bottom: 40px;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .main-title {
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .main-title {
    margin-bottom: 42px;
    font-size: 2.5rem;
  }
}

.subtitle-page {
  font-size: 1.25rem;
  font-family: "LatoBold", sans-serif;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .subtitle-page {
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .subtitle-page {
    font-size: 2rem;
  }
}

.secondary-title {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "LatoBold", sans-serif;
}
@media (min-width: 768px) {
  .secondary-title {
    font-size: 28px;
  }
}

.third-title {
  font-size: 16px;
  font-family: "LatoBold", sans-serif;
}
@media (min-width: 768px) {
  .third-title {
    font-size: 22px;
  }
}

.title {
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  border-bottom: 1px solid red;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .title {
    font-size: 32px;
  }
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 40px);
}
@media (min-width: 768px) {
  .container {
    width: calc(100% - 80px);
  }
}
.container--full-page {
  padding-top: 40px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .container--full-page {
    padding-top: 55px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .container--full-page {
    padding-top: 65px;
  }
}
.container--single-page {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .container--single-page {
    padding-bottom: 100px;
  }
}
.container--small {
  max-width: 980px;
}

.list {
  list-style: none;
  display: grid;
  gap: 1rem;
}
.list__item {
  display: grid;
  grid-template-columns: 0 1fr;
  gap: 1.75em;
  align-items: start;
  line-height: 1;
}
.list__item::before {
  content: attr(data-icon);
  font-size: 1em;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}

body::-webkit-scrollbar-track {
  background-color: #0b0426;
}

body::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.56);
  border-radius: 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
}

.button {
  border: 1px solid #F00519;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  padding: 10px 30px;
  color: #fff;
  display: flex;
  border-radius: 10px;
  width: fit-content;
  background: transparent;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.15s ease-in;
}
@media (min-width: 768px) {
  .button {
    font-size: 22px;
  }
}
.button:hover, .button:focus {
  background: #F00519;
}
.button--small {
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 6px;
}
.button--white {
  border: 1px solid #fff;
}
.button--white:hover, .button--white:focus {
  background: #fff;
  color: #313131;
}
.button.request-access {
  background-color: #0c1531;
  align-items: center;
  justify-content: center;
}
.button.request-access:hover, .button.request-access:focus {
  background: #F00519;
}
.button:focus-visible {
  outline: 0;
  border: 1px solid #F00519;
}

.paragraph {
  font-size: 16px;
  white-space: break-spaces;
}
@media (min-width: 1024px) {
  .paragraph {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .paragraph {
    font-size: 22px;
  }
}

input {
  height: 68px;
  border: solid 1px #fff;
  border-radius: 15px;
  background: transparent;
  color: #fff;
  font-size: 16px;
  padding: 0 20px;
  opacity: 0.75;
  transition: opacity 115ms ease-in-out;
  margin: 0;
  width: 100%;
  font-family: "Lato", sans-serif;
}
@media (min-width: 1200px) {
  input {
    font-size: 20px;
  }
}
.form-contact__input--small input {
  height: 40px;
}
@media (min-width: 525px) {
  .form-contact__input--small input {
    height: 48px;
  }
}
input:focus-visible {
  outline: 0;
  border: solid 2px #fff;
}

input:focus {
  opacity: 1;
  font-size: 16px;
}
@media (min-width: 1200px) {
  input:focus {
    font-size: 20px;
  }
}

input::placeholder {
  color: #fff;
}

textarea {
  height: 188px;
  border: solid 1px #fff;
  border-radius: 15px;
  background: transparent;
  color: #fff;
  font-size: 20px;
  padding: 20px;
  font-family: "Lato", sans-serif;
  opacity: 0.75;
  transition: opacity 115ms ease-in-out;
  margin: 0;
  width: 100%;
}
textarea:focus-visible {
  outline: 0;
  border: solid 2px #fff;
}

textarea:focus {
  opacity: 1;
}

img {
  width: 100%;
  max-width: 100%;
  display: block;
  border: 0;
}

.panel-list-image {
  display: flex;
  gap: 20px;
  margin: 0 auto 64px;
  width: 100%;
  justify-content: center;
}
@media (min-width: 768px) {
  .panel-list-image {
    margin-bottom: 100px;
  }
}
.panel-list-image__link {
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 320px;
  overflow: hidden;
}

.iconArrowPrev, .iconArrowNext {
  width: auto;
}

.iconArrowPrev {
  transform: rotate(-180deg);
}

.swiper-pagination {
  margin-top: 32px !important;
  position: relative !important;
}
.swiper-pagination > span {
  background-color: #fff;
}

div.swiper-button-next, div.swiper-button-prev {
  position: absolute;
  min-width: 68px;
  min-height: 68px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  z-index: 3 !important;
  display: flex;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .carouselPanel:hover div.swiper-button-next, .swiper-containerNews:hover div.swiper-button-next, .swiper-containerFormats:hover div.swiper-button-next, .carouselPanel:hover div.swiper-button-prev, .swiper-containerNews:hover div.swiper-button-prev, .swiper-containerFormats:hover div.swiper-button-prev {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

div.swiper-button-prev {
  left: 60px;
}
@media (min-width: 1024px) {
  .carouselPanel:hover div.swiper-button-prev, .swiper-containerNews:hover div.swiper-button-prev, .swiper-containerFormats:hover div.swiper-button-prev {
    transform: translateX(-50px);
  }
}

div.swiper-button-next {
  right: 60px;
}
@media (min-width: 1024px) {
  .carouselPanel:hover div.swiper-button-next, .swiper-containerNews:hover div.swiper-button-next, .swiper-containerFormats:hover div.swiper-button-next {
    transform: translateX(50px);
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.swiper-container--home .swiper-slide {
  border-radius: 14px;
}
.carouselPanel .swiper-slide {
  min-width: 90vw;
  max-width: 50vw;
}
@media (min-width: 1200px) {
  .carouselPanel .swiper-slide {
    min-width: 1024px;
    max-width: 1024px;
  }
}
.swiper-containerFormats .swiper-slide {
  border-radius: 14px;
}
.swiper-slide.our-format-list {
  margin: 0;
  aspect-ratio: initial;
  max-width: initial;
  height: auto;
  overflow: hidden;
}
.swiper-slide.slideFormats {
  max-width: initial;
  margin: 0;
  min-height: initial;
  height: initial;
  min-width: initial;
  aspect-ratio: initial;
  overflow: hidden;
}
.swiper-containerNews .swiper-slide {
  height: initial;
}

@media (min-width: 525px) {
  .listOurFormats .swiper-wrapper {
    grid-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .listOurFormats .swiper-wrapper {
    grid-gap: 36px;
    row-gap: 18px;
  }
}

.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important;
}

.swiper-pagination.disabled {
  display: none;
}

.navigation-hidden .swiper-button-next, .navigation-hidden .swiper-button-prev {
  display: none;
}

.modal {
  position: fixed;
  inset: 0;
  z-index: 20;
  padding: 30px;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal__overlay {
  position: fixed;
  inset: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.modal__close:hover {
  color: #fff;
}
.modal__close--bottom {
  display: none;
}
.modal-error .modal__close--bottom {
  display: block;
}
.modal__container {
  position: relative;
  z-index: 22;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: transparent;
  text-align: center;
  border-radius: 16px;
  display: grid;
  grid-template-rows: 1fr auto;
}
.modal-error .modal__container {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  margin: initial;
  padding: 30px;
  background-color: #101d41;
}
@media (min-width: 768px) {
  .modal-error .modal__container {
    width: 400px;
  }
}
.modal__container.form-visible {
  padding: 30px;
  background-color: #101d41;
  max-width: 1024px;
}
@media (max-width: 767px) {
  .modal__container.form-visible {
    overflow-y: auto;
  }
}
.modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-visible .modal__content {
  display: none;
}
.modal__form {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.form-visible .modal__form {
  display: flex;
}

.tooltip {
  position: fixed;
  top: 100px;
  right: 20px;
  background-color: #3b8cd9;
  display: flex;
  flex-direction: row;
  height: 50px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-left: 6px solid #2572bc;
  border-radius: 5px;
  overflow: hidden;
  color: #fff;
  pointer-events: none;
  opacity: 0;
  z-index: 21;
}
.tooltip__close {
  background: #2572bc;
  color: #fff;
  width: 40px;
  height: 50px;
  top: 0;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
}
.success .tooltip__close {
  display: flex;
}
.tooltip__message {
  padding-right: 10px;
  padding-left: 10px;
  display: block;
}
.success .tooltip__message {
  display: none;
}
.tooltip__message-success {
  display: none;
}
.success .tooltip__message-success {
  display: block;
}
.tooltip__check {
  background-color: #2572bc;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-left: 10px;
  display: none;
}
.success .tooltip__check {
  display: block;
}
.tooltip.show {
  animation: show_tooltip 1s ease forwards;
  opacity: 1;
  pointer-events: auto;
}
.tooltip.hide {
  animation: hide_tooltip 1s ease backwards;
}

@keyframes show_tooltip {
  0% {
    transform: translateX(100%);
  }
  40% {
    transform: translateX(-10%);
  }
  80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-20px);
  }
}
@keyframes hide_tooltip {
  0% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "LatoBold";
  src: url("../fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}
.titlePage {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 38px;
  margin-top: 131px;
  margin-bottom: 42px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-family: "LatoBold", sans-serif;
}

.homePopupAbout {
  margin-top: 60px;
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  .homePopupAbout {
    margin-top: 150px;
    margin-bottom: 100px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}