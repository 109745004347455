.panel-list-image {
  display: flex;
  gap: 20px;
  margin: 0 auto 64px;
  width: 100%;
  justify-content: center;

  @include mq($from: "tablet"){
    margin-bottom: 100px;
  }

  &__link {
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    max-width: 320px;
    overflow: hidden;
  }
}