@import "../scss/tools/all";
@import "../scss/settings/all";
@import "../scss/utilities/all";

@import "../html/header/header";
@import "../html/listNews/listNews";
@import "../html/ourFormats/ourFormats";
@import "../html/popAbout/popAbout";
@import "../html/footer/footer";
@import "../html/formats/formats";
@import "../html/news/news";
@import "../html/new/new";
@import "../html/format/format";
@import "../html/contact/contact";
@import "../html/carousel/carousel";
@import "../html/listFormats/listFormats";
@import "../html/about/about";
@import "elements/elements.titles";
@import "elements/elements.container";
@import "elements/elements.list";
@import "elements/elements.scrollbar";
@import "elements/elements.button";
@import "elements/elements.paragraph";
@import "elements/elements.input";
@import "elements/elements.textarea";
@import "elements/elements.image";
@import "components/components.panel-list-image";
@import "components/components.swiper";
@import "components/components.modal";
@import "components/components.tooltip";

@font-face {
  font-family: "Lato";
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "LatoBold";
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

.titlePage {
  width: 100%;
  text-align: center;
  color: $white;
  font-size: 38px;
  margin-top: 131px;
  margin-bottom: 42px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  @include font-family("bold");
}

.homePopupAbout {
  margin-top: 60px;
  margin-bottom: 100px;
  @include mq($from: "tablet") {
    margin-top: 150px;
    margin-bottom: 100px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}