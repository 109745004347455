@mixin hocus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin font-family($family) {
  @if ($family == "regular") {
    font-family: "Lato", sans-serif;
  }
  @elseif ($family == "bold") {
    font-family: "LatoBold", sans-serif;
  }
  @elseif ($family == "roboto") {
    font-family: 'Roboto', sans-serif;
  }
  @else {
    font-family: Baskerville, Garamond, Palatino, "Palatino Linotype", "Hoefler Text", "Times New Roman", serif;
  }
}

@mixin mq(
  $from: false,
  $until: false,
  $and: false,
  $media-type: all,
) {
  $min-width: 0;
  $max-width: 0;
  $query: "";

  //FROM: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) == number {
      $min-width: $from;
    } @else {
      $min-width: map-get($breakpoints, $from);
    }
  }

  //UNTIL: this breakpoint (exclusive)
  @if $until {
    @if type-of($until) == number {
      $max-width: $until - 1px;
    } @else {
      $max-width: map-get($breakpoints, $until) - 1px;
    }
  }

  @if $min-width != 0 {
    $query: "#{$query} and (min-width: #{$min-width})";
  }
  @if $max-width != 0 {
    $query: "#{$query} and (max-width: #{$max-width})";
  }
  @if $and {
    $query: "#{$query} and (#{$and})";
  }

  @if ($media-type == "all" and $query != "") {
    $media-type: "";
    $query: str-slice(unquote($query), 6);
  }

  @media #{$media-type + $query} {
    @content;
  }
}