textarea {
  height: 188px;
  border: solid 1px $white;
  border-radius: 15px;
  background: transparent;
  color: $white;
  font-size: 20px;
  padding: 20px;
  @include font-family("regular");
  opacity: 0.75;
  transition: opacity 115ms ease-in-out;
  margin: 0;
  width: 100%;

  &:focus-visible {
    outline: 0;
    border: solid 2px $white;
  }
}

textarea:focus {
  opacity: 1;
}