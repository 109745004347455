.ourFormat-1 {
  @include mq($from: "mobile-plus"){
    grid-column: 0 / 2;
    grid-row: 1 / 2;
  }
}

.ourFormat-2 {
  @include mq($from: "mobile-plus"){
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}

.ourFormat-3{
  @include mq($from: "mobile-plus"){
    grid-column: 2 / 4;
    grid-row: 1 / 3;
  }
}