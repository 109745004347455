.container {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 40px);

  @include mq($from: "tablet") {
    width: calc(100% - 80px);
  }

  &--full-page {
    padding-top: 40px;
    padding-bottom: 60px;
    @include mq($from: "tablet") {
      padding-top: 55px;
      padding-bottom: 100px;
    }
    @include mq($from: "wide") {
      padding-top: 65px;
    }
  }

  &--single-page {
      padding-top: 60px;
      padding-bottom: 60px;
      @include mq($from: "tablet") {
        padding-bottom: 100px;
      }
  }

  &--small {
    max-width: 980px;
  }
}