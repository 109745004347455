.filterPanel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 28px;
  font-size: 16px;
  gap: 5px;

  @include font-family("regular");
  @include mq($from: "mobile-plus") {
    gap: 10px;
  }
  @include mq($from: "tablet") {
    gap: 20px;
  }
}

.filterText {
  color: $white;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  @include mq($from: "wide") {
    text-align: initial;
    width: auto;
    margin-bottom: 0;
  }
}

.filter {
  color: $white;
  padding: 6px 15px;
  border-radius: 8px;
  opacity: 0.7;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;

  @include mq($from: "tablet") {
    padding: 12px 24px;
  }
}

.filterActive, .filter:hover {
  opacity: 1;
  background: transparent;
  border: 1px solid $border-active;
  transition: $background-transition ;

  &:hover {
    background: $red;
  }
}

.format {
  color: $white;
  display: flex;
  padding: 12px;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  gap: 10px;

  .swiper-containerFormats & {
    height: initial;
  }

  @include mq($from: "wide"){
    flex-direction: row;
    gap: 15px;
  }

  &__img {
    width: 100%;

    @extend .img-with-border;

    @include mq($from: "wide") {
      width: 60%;
    }
  }

  &__description {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    flex-grow: 1;

    .swiper-containerFormats & {
      @include mq($from: "wide") {
        gap: initial;
      }
    }

    @include mq($from: "wide") {
      margin-top: 0;
      flex-grow: initial;
    }

    @include mq($from: "wide") {
      width: 40%;
    }

    .swiper-containerFormats & {
      width: 100%;
      @include mq($from: "wide") {
        width: 50%;
      }
    }
  }

  &__inner {
    background: $dark-blue;
    border-radius: 25px;
    height: 100%;
    width: 100%;

    @extend .hover-effect;
  }

  &__genre {
    display: inline-block;
    padding: 4px 7px;
    border-radius: 6px;
    background-color: $bckg-genre-format;
    margin: auto auto 0;

    @include font-family("regular");
    font-size: 13px;

    @include mq($from: "wide") {
      display: none;
    }
  }

  &__subtitle {
    opacity: 0.8;
    font-size: 16px;
    text-align: center;

    @include mq($from: "wide") {
      text-align: left;
    }

    &.text-truncate {
      @include mq($until: "wide") {
        white-space: initial;
      }
    }
  }
}

.formatsTitleFormat {
  @include font-family("bold");
  text-transform: uppercase;
  color: $white;
  font-size: 17px;
  @include mq($from: "wide"){
    text-align: initial;
  }
}

.infosFormat {
  margin-top: auto;
  flex-direction: column;
  display: none;
  @include mq($from: "desktop"){
    display: flex;
  }
}

.infoFormat {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  @include mq($from: "wide") {
    margin-bottom: 0;
  }
}

.infoTitleFormat {
  opacity: 0.8;
  margin-right: auto;
  font-size: 16px;
}

.infoValueFormat {
  @include font-family("bold");
  font-size: 16px;
}