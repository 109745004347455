.date-new {
  opacity: 0.7;
  font-size: 13px;
  @include font-family("regular");
  margin-bottom: 10px;

  @include mq($from: "tablet") {
    font-size: 22px;
  }
}

.titleNew {
  @include font-family("bold");
  font-size: 16px;
  margin-bottom: 10px;
  @include mq($from: "tablet") {
    font-size: 24px;
  }
  @include mq($from: "tablet") {
    font-size: 34px;
  }
}

.subTitleNew {
  opacity: .8;
  @include font-family("bold");
  font-size: 16px;
  margin-bottom: 40px;

  @include mq($from: "tablet") {
    font-size: 22px;
  }
}

.newPicture {
  width: 100%;
  display: block;
}

.picture {
  border-radius: 16px;
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto 40px;
}

.description-new {
  margin-bottom: 40px;
  &__text {
    margin-bottom: 20px;
  }
  &__chapo {
    font-weight: bold;
  }
}

