.iconArrowPrev, .iconArrowNext {
  width: auto;
}

.iconArrowPrev {
  transform: rotate(-180deg);
}

.swiper-pagination {
  margin-top: 32px !important;
  position: relative !important;

  & > span {
    background-color: $white;
  }
}

div.swiper-button-next, div.swiper-button-prev {
  position: absolute;
  min-width: 68px;
  min-height: 68px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  z-index: 3 !important;
  display: flex;
  opacity: 0;
  visibility: hidden;
  @extend .transition-all;

  .carouselPanel:hover &, .swiper-containerNews:hover &, .swiper-containerFormats:hover &{
    @include mq($from: "desktop") {
      opacity: 1;
      visibility: visible;
      transform:translateX(0);
    }
  }
}

div.swiper-button-prev {
  left: 60px;
  .carouselPanel:hover &, .swiper-containerNews:hover &, .swiper-containerFormats:hover &{
    @include mq($from: "desktop") {
      transform:translateX(-50px);
    }
  }
}

div.swiper-button-next {
  right: 60px;
  .carouselPanel:hover &, .swiper-containerNews:hover &, .swiper-containerFormats:hover &{
    @include mq($from: "desktop") {
      transform:translateX(50px);
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  .swiper-container--home & {
    border-radius: 14px;
  }

  .carouselPanel & {
    min-width: 90vw;
    max-width: 50vw;
    @include mq($from: "wide"){
      min-width: 1024px;
      max-width: 1024px;
    }
  }

  .swiper-containerFormats &{
    border-radius: 14px;
  }

  &.our-format-list {
    margin: 0;
    aspect-ratio: initial;
    max-width: initial;
    height: auto;
    overflow: hidden;
  }

  &.slideFormats {
    max-width: initial;
    margin: 0;
    min-height: initial;
    height: initial;
    min-width: initial;
    aspect-ratio: initial;
    overflow: hidden;
  }

  .swiper-containerNews & {
    height: initial;
  }
}

.swiper-wrapper {
  .listOurFormats & {
    @include mq($from: "mobile-plus") {
      grid-gap: 12px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    @include mq($from: "wide") {
      grid-gap: 36px;
      row-gap: 18px;
    }
  }
}

// if sliders has less than 2 slides
.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important;
}
.swiper-pagination.disabled {
  display: none;
}
.swiper-button-next, .swiper-button-prev {
  .navigation-hidden &{
    display: none;
  }
}