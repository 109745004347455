.hover-effect {
  transition: transform .25s ease, background-color .3s ease;
  &:hover {
    @include mq($from: "tablet") {
      transform: translateY(-4px);
      background-color: lighten($dark-blue, 15%);
      .swiper-slide & {
        transform: none;
      }
    }
  }
}