.headerPanel {
  height: 72px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
  background-color: $bckg-header;

  @include mq($from: "tablet") {
    box-shadow: 0 10px 10px -10px #00000059;
    border-bottom: 1px solid rgba(255,255,255,0.035);
  }
}

.headerItem {
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  height: 100%;
  transition: 225ms ease-in-out;
  position: relative;

  @include font-family("bold");
}

.headerItem:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.navbar-toggler {
  display: block;
  padding: 0;
  margin: 0 22px 0 auto;
  background-color: transparent;
  appearance: unset;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 0px;
  @include mq($from: "tablet"){
    display: none;
  }

  & > img {
    display: initial;
    width: auto;
  }
}

.menuMobile {
  visibility: hidden;
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #192857;
  z-index: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: visibility 0s, opacity 250ms;
  padding-bottom: 72px;
}

.header-logo {
  max-width: 64px;
  @include mq($from: "tablet") {
    max-width: 88px;
  }
}

@media screen and (max-width: 768px) {

  .headerItem {
    visibility: hidden;
  }

  .menuMobileVisible > .menuMobile  {
    opacity: 1;
    visibility: visible;
  }

  .headerItemMobile {
    font-size: 18px;
    color: $white;
    @include font-family("bold");
    text-transform: uppercase;
    position: relative;
    height: 72px;
    line-height: 72px;

    @include mq($until: "tablet") {
      text-align: center;
      width: 100%;
    }
  }

  .headerItemMobile:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      width: 28px;
      top: 100%;
      border-bottom: 1px solid $red;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .iconMenu {
    opacity: 1;
    transition: 115ms;
    right: 22px;
  }

  .iconMenuCross {
    position: absolute;
    opacity: 0;
    left: 3px;
    transform: rotate(-45deg);
    transition: 115ms;
  }

  .menuMobileVisible > .navbar-toggler > .iconMenu {
    opacity: 0;
    transform: rotate(45deg);
  }


  .menuMobileVisible > .navbar-toggler > .iconMenuCross {
    opacity: 1;
    transform: rotate(0deg);
  }

  .homeMobile {
    visibility: visible;
  }
}