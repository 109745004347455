html {
  font-size: 1rem;
  @include font-family("roboto");
  color: $white;
  line-height: 1.4;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding-top: 72px;
  background: linear-gradient(90deg, rgba(13,22,85,1) 0%, rgba(0,9,56,1) 50%, rgba(13,22,85,1) 100%);
}

footer {
  margin-top: auto;
}