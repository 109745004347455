input {
  height: 68px;
  border: solid 1px $white;
  border-radius: 15px;
  background: transparent;
  color: $white;
  font-size: 16px;
  padding: 0 20px;
  opacity: 0.75;
  transition: opacity 115ms ease-in-out;
  margin: 0;
  width: 100%;
  @include font-family("regular");

  @include mq($from : "wide") {
    font-size: 20px;
  }

  .form-contact__input--small & {
    height: 40px;
    @include mq($from: "mobile-plus") {
      height: 48px;
    }
  }

  &:focus-visible {
    outline: 0;
    border: solid 2px $white;
  }
}

input:focus {
  opacity: 1;
  font-size: 16px;

  @include mq($from : "wide") {
    font-size: 20px;
  }
}

input::placeholder {
  color: $white;
}