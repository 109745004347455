.list {
  list-style: none;
  display: grid;
  gap: 1rem;
  &__item {
    display: grid;
    grid-template-columns: 0 1fr;
    gap: 1.75em;
    align-items: start;
    line-height: 1;
    &::before {
      content: attr(data-icon);
      font-size: 1em;
    }
  }
}