.layout {
  padding: 0;
  list-style: none;
  font-size: 0;
  display: flex;
  margin: 0 0 0 -20px;
  flex-wrap: wrap;
  justify-content: center;
  @include mq($from: tablet){
    margin: 0 0 0 -30px;
  }
  &__item{
    padding-left: 20px;
    width: 100%;
    margin-bottom: 20px;
    @include mq($from: tablet){
      padding-left: 30px;
      margin-bottom: 30px;
    }
  }
}