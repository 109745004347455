.aboutPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 130px;
}

.textsAboutPanel {
  display: flex;
  flex-direction: column;
}