.background-contact {
  background: $bckg-contact;
}

.form-contact {
  @include font-family("regular");
  font-size: 20px;
  &__input {
    margin-bottom: 20px;
    position: relative;
  }

  &__input--small {
    margin: 0;
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__label {
    position: absolute;
    top: -10px;
    left: 20px;
    color: $white;
    font-size: 16px;
    background: $bckg-contact;
    padding: 0 10px;
  }

  &__label--format {
    background: $bckg-contact-format;
  }

  &__text {
    font-size: 14px;
    opacity: .5;
    @include font-family("roboto");
    display: none;
    @include mq($from: "tablet") {
      display: block;
      font-size: 16px;
    }
    @include mq($from: "desktop") {
      font-size: 18px;
    }
  }

  &__title {
    @include font-family("bold");
    font-size: 18px;
    @include mq($from: "tablet") {
      font-size: 20px;
    }
    @include mq($from: "desktop") {
      font-size: 24px;
    }
  }

  &__input-field--medium {
    max-height: 125px;
    @include mq($from: "tablet") {
      max-height: 150px;
    }
    @include mq($from: "desktop") {
      max-height: 200px;
    }
  }

  &__input-field {
    font-size: 18px;
  }
}

.email-response {
  position: absolute;
  top: 100%;
  left: 0;
  background: red;
  color: $white;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  display: none;
  font-size: 14px;
  z-index: 3;
  &.visible {
    display: block;
  }
}

.send {
  padding: 16px 42px;
  border: solid 1px #F00519;
  border-radius: 15px;
  background: transparent;
  color: white;
  width: fit-content;
  grid-column: 1 / 4;
  cursor: pointer;
  margin: 0 auto;
}