body::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}

body::-webkit-scrollbar-track {
  background-color: $dark-blue-2;
}

body::-webkit-scrollbar-thumb {
  background-color: hsla(0,0%,100%,.56);
  border-radius: 10px;
  -webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}