@mixin widths($widths-columns, $widths-breakpoint: null) {
  @each $widths-denominator in $widths-columns {
    @for $widths-numerator from 1 through $widths-denominator {
      .u-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint} {
        width: ($widths-numerator / $widths-denominator) * 100% !important;
      }
    }
  }
}

@include widths(1 2 3 4 5 6 7 8 9 10 11 12);

@include mq($from: "mobile") {
  @include widths(1 2 3 4 5 6 7 8 9 10 11 12, \@sm);
}

@include mq($from: "mobile-plus") {
  @include widths(1 2 3 4 5 6 7 8 9 10 11 12, \@smp);
}

@include mq($from: "tablet") {
  @include widths(1 2 3 4 5 6 7 8 9 10 11 12, \@md);
}

@include mq($from: "desktop") {
  @include widths(1 2 3 4 5 6 7 8 9 10 11 12, \@lg);
}

@include mq($from: "wide") {
  @include widths(1 2 3 4 5 6 7 8 9 10 11 12, \@xl);
}