.footer {
  padding: 20px;
  background: $bckg-footer;
  @include mq($from: "wide") {
    padding-right: 40px;
    padding-left: 40px;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    @include mq($from: "mobile-plus") {
      flex-direction: row;
    }
    @include mq($from: "tablet") {
      padding-bottom: 0;
    }
  }

  &__brand {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__logo{
    max-width: 80px;
    width: 100%;
  }

  &__links {
    color:$white;
    display: flex;
    gap: 20px;
  }

  &__link {
    color: currentColor;
    @include font-family("regular");
    font-size: 14px;
    @include mq($from: "tablet") {
      font-size: 16px;
    }
  }

  &__copyright {
    opacity: .5;
    font-size: 14px;
    @include font-family("regular");
  }

  &__title {
    font-size: 14px;
    color: $grey;
    display: none;
    @include font-family("regular");
    @include mq($from: "tablet") {
      display: block;
    }
  }
}