.panel-about {
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  gap: 0;
  background-color: $dark-blue;

  @include mq($from: "desktop") {
    gap: 40px;
    flex-direction: row;
    padding: 0;
  }

  &__img {
    width: 100%;

    @include mq($from: "desktop") {
      width: 60%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 100%;

    @include mq($from: "desktop") {
      padding: 20px 20px 20px 0;
      width: 40%;
    }
  }

  &__container {
    margin-bottom: 40px;

    @include mq($from: "desktop") {
      margin-bottom: 90px;
    }
  }
}

.imgAbout {
  width: 100%;
  max-width: 100%;
}