.tooltip {
  position: fixed;
  top: 100px;
  right: 20px;
  background-color: $bckg-tooltip;
  display: flex;
  flex-direction: row;
  height: 50px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-left: 6px solid darken($bckg-tooltip, 10%);
  border-radius: 5px;
  overflow: hidden;
  color: $white;
  pointer-events: none;
  opacity: 0;
  z-index: 21;

  &__close {
    background: darken($bckg-tooltip, 10%);
    color: $white;
    width: 40px;
    height: 50px;
    top: 0;
    cursor: pointer;

    display: none;
    justify-content: center;
    align-items: center;

    .success &{
      display: flex;
    }
  }

  &__message {
    padding-right: 10px;
    padding-left: 10px;
    display: block;

    .success & {
      display: none;
    }
  }

  &__message-success {
    display: none;

    .success & {
      display: block;
    }
  }

  &__check {
    background-color: darken($bckg-tooltip, 10%);
    color: $white;

    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-left: 10px;

    display: none;

    .success &{
      display: block;
    }
  }

  &.show {
    animation: show_tooltip 1s ease forwards;
    opacity: 1;
    pointer-events: auto;
  }

  &.hide {
    animation: hide_tooltip 1s ease backwards;
  }
}

@keyframes show_tooltip {
  0%{
    transform: translateX(100%);
  }

  40%{
    transform: translateX(-10%);
  }

  80%{
    transform: translateX(0%);
  }

  100%{
    transform: translateX(-20px);
  }
}

@keyframes hide_tooltip {
  0%{
    transform: translateX(-10px);
  }

  40%{
    transform: translateX(0);
  }

  80%{
    transform: translateX(-10%);
  }

  100%{
    transform: translateX(-100%);
  }
}