.titleFormat {
  margin-top: 52px;
  margin-bottom: 5px;
}

.subTitleFormat {
  opacity: 0.8;
  @include font-family("regular");
  font-size: 16px;
  margin-bottom: 20px;

  @include mq($from: "tablet") {
    font-size: 24px;
  }
}

.format-panel{
  margin-bottom: 40px;
  display: grid;
  gap: 20px;

  @include mq($from: "desktop") {
    grid-template-columns: 60% 1fr;
    gap: 20px;
  }

  @include mq($from: "desktop") {
    gap: 40px;
  }

  &__picture {
    @extend .img-with-border;
  }

  &__picture-overlay {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    z-index: -1;
    display: none;

    @include mq($from: "tablet") {
      display: block;
    }

    & > img {
      filter: blur(20px);
      opacity: .25;
    }
  }

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 20px;
  }

  &__infos-inner {
    background-color: $dark-blue;
    justify-content: center;
    border-radius: 18px;
    padding: 25px 22px;
  }

  &__title-info{
    margin-bottom: 20px;
    text-align: center;
    @include mq($from: "wide") {
      margin-bottom: 40px;
    }
  }

  &__image {
    border-radius: 18px;
    overflow: hidden;
    display: block;
  }
}

.formatInfo {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 16px;

  @include mq($from: "tablet") {
    font-size: 20px;
  }
}

.formatInfo:last-child {
  margin-bottom: 0;
}

.formatInfoTitle {
  opacity: 0.8;
  margin-right: auto;
}

.formatEpisodesPanel {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  justify-content: center;
}

.format-episode {
  @include font-family("regular");
  font-size: 13px;
  width: 155px;
  gap: 10px;
  height: 50px;
  display: grid;
  grid-template-columns: 10px 1fr 15px;
  align-items: center;
  border-radius: 18px;
  background-color: $bckg-format-episode;
  cursor: pointer;
  user-select: none;
  padding: 0 20px;

  @include mq($from: "tablet") {
    width: 202px;
    font-size: 16px;
  }
}

.descriptionFormat {
  margin-bottom: 40px;
}

.player {
  width: 100%;

  & > iframe {
    min-height: 300px;

    @include mq($from: 'tablet') {
      min-height: 500px;
    }
    @include mq($from: 'wide') {
      min-height: 720px;
    }
  }
}
