h1 {
  all: unset;
}

h2 {
  all: unset;
}

.main-title {
  text-transform: uppercase;
  @include font-family("bold");
  margin-bottom: 40px;
  font-size: 1.5rem;
  @include mq($from: "tablet"){
    font-size: 2rem;
  }
  @include mq($from: "desktop"){
    margin-bottom: 42px;
    font-size: 2.5rem;
  }
}

.subtitle-page {
  font-size: 1.25rem;
  @include font-family("bold");
  margin-bottom: 20px;
  @include mq($from: "tablet"){
    font-size: 1.5rem;
  }
  @include mq($from: "desktop"){
    font-size: 2rem;
  }
}

.secondary-title {
  font-size: 16px;
  text-transform: uppercase;
  @include font-family("bold");

  @include mq($from: "tablet") {
    font-size: 28px;
  }
}

.third-title {
  font-size: 16px;
  @include font-family("bold");

  @include mq($from: "tablet") {
    font-size: 22px;
  }
}

.title {
  color: $white;
  font-size: 22px;
  text-transform: uppercase;
  border-bottom: 1px solid red;
  margin-bottom: 40px;

  @include mq($from: "tablet") {
    font-size: 32px;
  }
}