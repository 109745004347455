.ml-auto{
  margin-left: auto;
}

.m-auto {
  margin: 0 auto;
}

$max: 60;
$offset: 5;
$unit: 'px';
@mixin list-margin($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}

@include list-margin('.mt-', 'margin-top');
@include list-margin('.mb-', 'margin-bottom');