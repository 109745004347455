.modal{
  position: fixed;
  inset: 0;
  z-index: 20;
  padding: 30px;
  width:  100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition:visibility 0s linear 0.1s,opacity 0.3s ease;

  &.open{
    visibility:visible;
    opacity: 1;
    transition-delay:0s;
  }

  &__overlay{
    position: fixed;
    inset: 0;
    z-index: 21;
    background-color: rgba(0,0,0,0.7);
  }

  &__close{
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: $white;
    font-weight: bold;
    cursor: pointer;

    &:hover{
      color: $white;
    }
  }

  &__close--bottom {
    display: none;

    .modal-error & {
      display: block;
    }
  }

  &__container{
    position: relative;
    z-index: 22;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    background-color: transparent;
    text-align: center;
    border-radius: 16px;
    display: grid;
    grid-template-rows: 1fr auto;

    .modal-error & {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 400px;
      margin: initial;
      padding: 30px;
      background-color: $bckg-modal;

      @include mq($from: 'tablet') {
        width: 400px;
      }
    }

    &.form-visible {
      padding: 30px;
      background-color: $bckg-modal;
      max-width: 1024px;

      @include mq($until: 'tablet') {
        overflow-y: auto;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    .form-visible & {
      display: none;
    }
  }

  &__form {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .form-visible & {
      display: flex;
    }
  }
}