.list-other-format {
  &.listPanel {
    margin-bottom: 45px;
  }
  &__img-content {
    width: 100%;

    & > div {
      @extend .img-with-border--small;
    }

    @include mq($from: "wide") {
      width: 50%;
    }
  }
}

div.swiper-button-next--formats, div.swiper-button-prev--formats {
  top: 42%;
}