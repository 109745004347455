.carouselPanel {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow-x: hidden;
  position: relative;
  @include mq($from: "desktop") {
    margin-top: 65px;
    margin-bottom: 60px;
  }
}

.title-carousel {
  font-size: 28px;
  text-transform: uppercase;
  @include mq($from: "tablet") {
    font-size: 38px;
  }
}

.main-title-carousel {
  @include font-family("regular");
  color:  #F00519;
}

.subtitle-carousel {
  @include font-family("bold");
  color: $white;
}

.carousel {
  position: relative;
  margin-top: 40px;
}


div.swiper-button-next--home, div.swiper-button-prev--home {
  display: flex;
  top: 55%;
}

div.swiper-button-next::after, div.swiper-button-prev::after {
  content: '';
}

div.swiper-button-next.swiper-button-disabled, div.swiper-button-prev.swiper-button-disabled {
  pointer-events: initial;
}

@media screen and (max-width: 1900px) {
  .swiper-slide {
    min-height: auto;
  }
}