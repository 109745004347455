a {
  text-decoration: none;
  color: $white;

  .description-new__text &, .descriptionFormat & {
    border-bottom: 1px solid $red;
    text-transform: capitalize;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
  }
}

.u-global-link::before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: transparent;
}